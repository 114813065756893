
#toast-container {
  /*overrides*/
  > div {
    border-radius: 0; // 3px 3px 3px 3px;
    box-shadow: none; // 0 0 12px #999999;
    overflow: visible; // needed for the floating close button;
    margin-bottom: 0.625em;
    opacity: .9;
    &:hover {
      box-shadow: none; // 0 0 12px #000000;
      border-radius: 0; // 3px 3px 3px 3px;
      opacity: 1;
    }
  }
  .toast-action {
    width: 100%;
    margin-top: 10px;
    border-radius: 1.3rem;
    background-color: rgba(0, 0, 0, .3);
  }
}

