div.rich-email-content {  
  table[bgcolor="#606972"] {
    display: none;
  }
  center {
    background-color: inherit !important
  }
  table[align="center"] {
    background-color: inherit;
  }
  html,
  body {
    margin: 0 auto !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
  * {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  div[style*="margin: 16px 0"] {
    margin: 0 !important;
  }
  table,
  td {
    tr {
      border-left: none;
    }
  }
  ul {
    li {
      font-size: 13px !important;
    }
  }
  &.is-modal-view {
    table[align="center"] {
      width: auto !important;
    }  
  }
  table[align="left"] {
    display: none;
  }

  table table table {
    table-layout: auto;
  }
  img {
    -ms-interpolation-mode: bicubic;
  }
  *[x-apple-data-detectors],
  .x-gmail-data-detectors,
  .x-gmail-data-detectors *,
  .aBn {
    border-bottom: 0 !important;
    cursor: default !important;
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }
  .a6S {
    display: none !important;
    opacity: 0.01 !important;
  }
  img.g-img + div {
    display: none !important;
  }
  .button-link {
    text-decoration: none !important;
  }
  .email-container {
    border: 1px solid gainsboro;
    width: 500px !important;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .email-container {
      min-width: 375px !important;
    }
  }
  @media screen and (max-width: 480px) {
    div > u ~ div .gmail {
      min-width: 100vw;
    }
  }
  .button-td,
  .button-a {
    transition: all 100ms ease-in;
  }
  .button-td:hover,
  .button-a:hover {
    opacity: 0.9;
  }
  @media screen and (max-width: 600px) {
    .email-container {
      width: 100% !important;
      margin: auto !important;
    }
    .fluid {
      max-width: 100% !important;
      height: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .stack-column,
    .stack-column-center {
      display: block !important;
      width: 100% !important;
      max-width: 100% !important;
      direction: ltr !important;
    }
    .stack-column-center {
      text-align: center !important;
    }
    .center-on-narrow {
      text-align: center !important;
      display: block !important;
      margin-left: auto !important;
      margin-right: auto !important;
      float: none !important;
    }
    table.center-on-narrow {
      display: inline-block !important;
    }
  }
}
