yc-button {
  yc-fa:first-of-type {
    margin-right: none;
    & + yc-i18n {
      margin-left: .5rem;
    }
  }
}

.dropdown-item.with-icon-description {
  display: flex;
  width: 300px;
  white-space: normal;

  yc-fa {
    margin-right: .25rem;
  }
}

yc-switch {
  .wrapper {
    background-color: #0974a1!important;
  }
}